import React, { useEffect, useState, useCallback, useRef } from 'react';
import useIntersectionObserver from '@react-hook/intersection-observer';
import { Link, useStaticQuery, graphql } from 'gatsby';
import verticalhex from '../img/vertical-hexes.png';
import animlogo from '../img/hex-fill_v6.gif';
import useScrollAnimation from '../utils/use-scrollanimation2';
import { useSpring, animated as a } from 'react-spring';
import interpolateAndClamp from '../utils/interpolate-and-clamp';
import styled from 'styled-components';
import featImg from '../img/home-beefitarian.jpg';
import annilogo from '../img/anniversary-logo.png';
import HomeMobileSlider from './HomeMobileSlider';
import { getImage } from 'gatsby-plugin-image';
import AnimDesire from './AnimDesire';

const StyledPanel1 = styled.div`
  h1,
  h2 {
    opacity: 0;
    transition: opacity 1.2s ease-out;
    position: absolute;
    text-align: center;
    width: 100%;
    left: 0;
    z-index: 6;
    text-transform: uppercase;
    font-family: 'Roboto';
    padding: 0 10%;
    box-sizing: border-box;
  }
  h1 {
    top: ${(props) => props.vh / 2 - 230 + 'px'};
    letter-spacing: 18px;
    text-indent: 23px;
    font-weight: 500;
    font-size: 28px;
  }
  h2 {
    top: ${(props) => props.vh / 2 + 166 + 'px'};
    letter-spacing: 3px;
    text-indent: 3px;
    font-weight: 400;
    font-size: 22px;
    text-align: center;
  }
  h1.reveal,
  h2.reveal {
    opacity: 1;
  }
  .hexImg {
    height: ${(props) => props.vh + 'px'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -104px;
    img {
      display: block;
      width: 100%;
      flex: 0 0 auto;
      height: auto;
      max-width: 200px;
    }
  }
  h2.left,
  h2.right {
    top: ${(props) => props.vh / 2 + 262 + 'px'};
    width: auto;
    padding: 0;
    font-size: 16.5px;
    margin-top: 20px;
  }
  h2.left {
    left: calc(50% - 95px);
  }
  h2.right {
    left: calc(50% + 42px);
  }
  .anniversary-logo {
    position: absolute;
    left: calc(50% - 25px);
    top: ${(props) => props.vh / 2 + 262 + 'px'};
    z-index: 3;
    opacity: 0;
    transition: opacity 1.2s ease-out;
    max-width: 50px;
    width: 100%;
    height: auto;
    &.reveal {
      opacity: 1;
    }
  }
  @media (max-height: 640px) {
    h1 {
      top: ${(props) => props.vh / 2 - 182 + 'px'};
      padding: 0 5%;
    }
    h2 {
      padding: 0 5%;
    }
    .hexImg {
      margin-top: -84px;
      /* img {
        max-width: 250px;
      } */
    }
  }
  @media (max-height: 500px) {
    h1 {
      top: ${(props) => props.vh / 2 - 140 + 'px'};
      font-size: 24px;
    }
    h2 {
      top: ${(props) => props.vh / 2 + 138 + 'px'};
      font-size: 18px;
    }
    .hexImg {
      img {
        max-width: 200px;
      }
    }
  }
`;

const StyledLabel = styled.div`
  /* position: absolute;
  right: 0;
  top: 0px;
  z-index: 50;
  padding: 4px 19px;
  background-color: #0095c8;
  font-family: 'Oswald';
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 1.1rem;
  box-shadow: 0px 0px 10px 2px rgba(53, 53, 53, 0.7);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  &.case-study {
    background-color: #ed8b00;
  } */
`;

const StyledHexes = styled.div`
  padding: 0px 0px;
  height: 1200px;
  overflow: hidden;
  img {
    display: block;
    margin: 0 auto;
    position: relative;
    @media (max-width: 444px) {
      left: calc(50% - 222px);
    }
  }
`;

const StyledPanel = styled.div`
  /* height: 750px;
  position: relative;
  overflow: hidden;

  .featImg {
    width: 100%;
    position: absolute;
    bottom: calc(450px - 97px);
    transition: all 900ms cubic-bezier(0.17, 0.13, 0.5, 1.37);
  }

  .content {
    position: absolute;
    max-width: none;
    bottom: 44px;
    height: 230px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 55px;
    p {
      font-size: 30px;
    }
  }
  h2 {
    text-transform: uppercase;
    letter-spacing: 1.6px;
    margin: 0 0 52px;
    font-size: 4rem;
    line-height: 4rem;
    color: white;
    font-weight: 400;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 3rem;
      line-height: 3rem;
    }
  }

  h3 {
    color: #009bce;
    font-weight: 300;
    text-transform: uppercase;
    margin-top: -50px;
    margin-bottom: 50px;
    width: 555px;
    text-align: center;
  }
  /* improvements for small-screen mobiles */
  /* @media (max-width: 374px) {
    h2 {
      font-size: 2.3rem;
      line-height: 3rem;
    }
    a.cta {
      font-size: 1.1rem;
      padding: 7px 18px;
      letter-spacing: 2px;
    }
  }  */
  .panel-creativity {
    max-width: 950px !important;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 20px;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .copy-wrapper {
      padding-top: 0 !important;
    }
    h2#creativity {
      font-family: 'Roboto';
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      line-height: 60px;
      letter-spacing: 4.2px;
      color: #fff;
      -webkit-text-fill-color: #000;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #fff;
      span {
        color: #fff;
        -webkit-text-fill-color: #fff;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #fff;
      }
      .animate-desire {
        /* opacity: 0; */
      }
      @media only screen and (max-width: 900px) {
        font-size: 53px;
        line-height: 1.1;
      }
    }
    .copy-wrapper {
      padding-bottom: 0 !important;
      padding-top: 100px;
    }
    @media only screen and (max-width: 900px) {
      height: 60vh;
      margin-top: 0;
      justify-content: flex-start;
      margin-top: 10vh;
    }
  }
`;

const StyledPanel3 = styled.div`
  background-color: #357db5;
  padding: 90px 20px 50px;
  box-sizing: border-box;
  .content {
    max-width: none;
    text-align: center;
    margin-top: 60px;

    h2 {
      text-transform: uppercase;
      letter-spacing: 1.6px;
      margin: 0 0 52px;
      font-size: 3rem;
      line-height: 62px;
      color: white;
      font-weight: 400;
      text-align: center;
      @media (max-width: 600px) {
        font-size: 3rem;
        line-height: 3.25rem;
      }
    }

    h3 {
      color: var(--White, #fff);
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px; /* 150% */
      letter-spacing: 0.32px;
      margin: 30px auto 50px;
      text-align: left;
      width: 555px;
      text-align: center;
      @media (max-width: 600px) {
        width: auto;
      }
    }
    a.cta {
      letter-spacing: 0.9px;
      padding: 15px 42px;
      font-size: 18px;
      line-height: 1;
      border-width: 2px;
    }
  }
  /* improvements for small-screen mobiles */
  @media (max-width: 374px) {
    .content h2 {
      font-size: 2.3rem;
      line-height: 3rem;
    }
    .content h3 {
      font-size: 1.17em;
    }
    a.cta {
      font-size: 1.1rem;
      padding: 7px 18px;
      letter-spacing: 2px;
    }
  }
`;

const WithStaticData = React.memo((props) => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "hex-bg.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 1639, height: 629)
          }
        }
        annilogo: file(relativePath: { eq: "anniversary-logo.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 50, height: 57)
          }
        }
        panel2left: file(relativePath: { eq: "case-study-bkg.jpg" }) {
          publicURL
        }
        panel2right: file(relativePath: { eq: "panel-2-hexes.png" }) {
          publicURL
        }
        parallaxImage1: file(relativePath: { eq: "home/laclare.jpg" }) {
          childImageSharp {
            gatsbyImageData(breakpoints: [500, 750, 1440, 1920])
          }
        }
        parallaxImage2: file(relativePath: { eq: "home/charter.jpg" }) {
          childImageSharp {
            gatsbyImageData(breakpoints: [500, 750, 1440, 1920])
          }
        }
        parallaxImage3: file(relativePath: { eq: "home/berres.jpg" }) {
          childImageSharp {
            gatsbyImageData(breakpoints: [500, 750, 1440, 1920])
          }
        }
        parallaxImage4: file(relativePath: { eq: "home/legit.jpg" }) {
          childImageSharp {
            gatsbyImageData(breakpoints: [500, 750, 1440, 1920])
          }
        }
        parallaxImage5: file(relativePath: { eq: "home/shopko.jpg" }) {
          childImageSharp {
            gatsbyImageData(breakpoints: [500, 750, 1440, 1920])
          }
        }
        parallaxImage6: file(relativePath: { eq: "home/jmi.jpg" }) {
          childImageSharp {
            gatsbyImageData(breakpoints: [500, 750, 1440, 1920])
          }
        }
        monitorimg: file(relativePath: { eq: "white-monitor-outline.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 540, height: 449)
          }
        }
        screenimg: file(relativePath: { eq: "beefitarian-homepage.jpg" }) {
          publicURL
        }
        ogimg: file(relativePath: { eq: "og-img/home.jpg" }) {
          publicURL
        }
        allWpPost(limit: 2, sort: { fields: [date], order: DESC }) {
          edges {
            node {
              id
              title
              link
              seo {
                metaDesc
              }
              featuredImage {
                node {
                  sourceUrl
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED
                        width: 390
                        height: 370
                        transformOptions: { fit: COVER, cropFocus: CENTER }
                      )
                    }
                  }
                }
              }
            }
          }
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  );
  const renderedChildren = props.children(data);
  return renderedChildren && React.Children.only(renderedChildren);
});

const HomeMobile = (props) => {
  const observerRef = useRef(null);
  const creativityRef = useRef(null);
  const [dispatch] = useScrollAnimation();
  const [showH1, setShowH1] = useState(false);
  const [showH2, setShowH2] = useState(false);
  // const [bigPictureActive, setBigPictureActive] = useState(false);
  //iOS dynamically changes the height on scroll.  We want to center our
  //splashscreen in the visible viewport, so we need this
  const [initialVisibleHeight, setVisibleHeight] = useState(0);
  const [imgpos, set] = useSpring(() => ({ from: { left: 0, top: 0 } }));
  const [animStart, setAnimStart] = useState(0);
  const hexMove = (item) => {
    if (typeof item.scrollPos !== 'undefined') {
      const left = interpolateAndClamp([0, 1], [0, 620], item.scrollPct);
      const top = interpolateAndClamp([0, 1], [0, 100], item.scrollPct);
      set({ left: left, top: top });
    }
  };

  // const bigPictureInView = (item) => {
  //   if (item && item.isComplete) {
  //     setBigPictureActive(true);
  //   }
  // };

  // useEffect(() => {
  //   // dispatch({
  //   //   type: 'registerItem',
  //   //   start: 0,
  //   //   end: 480,
  //   //   id: 'hex-move',
  //   //   onUpdate: hexMove,
  //   // });
  //   // console.log({ vh: props.vh });
  //   dispatch({
  //     type: 'registerItem',
  //     start: 1650,
  //     end: 1651,
  //     id: 'hex-move',
  //     onUpdate: bigPictureInView,
  //   });
  // }, [props.vh]);

  useEffect(() => {
    setVisibleHeight(window.innerHeight);
    setTimeout(() => {
      setShowH1(true);
    }, 1300);

    setTimeout(() => {
      setShowH2(true);
    }, 1700);
  }, []);

  const setHeight = useCallback(() => {
    const container = document.getElementById('creativity');
    if (container) {
      const top = container.offsetTop;
      const height = container.offsetHeight;
      const viewHeight = window.innerHeight;
      setAnimStart(top + height - viewHeight);
    }
  }, []);

  const { isIntersecting } = useIntersectionObserver(creativityRef);

  useEffect(() => {
    setHeight();
    window.addEventListener('resize', setHeight);
    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, [isIntersecting]);

  return (
    <WithStaticData>
      {(data) => {
        return (
          <>
            <StyledPanel1 vh={initialVisibleHeight}>
              <h1 className={showH1 ? 'reveal' : ''}>Element</h1>
              <h2 className={showH2 ? 'reveal' : ''}>Fully Integrated Marketing</h2>
              <a.img className={showH2 ? 'reveal anniversary-logo' : 'anniversary-logo'} src={annilogo} style={{}} />
              <h2 className={showH2 ? 'reveal left' : 'left'}>2003</h2>
              <h2 className={showH2 ? 'reveal right' : 'right'}>2023</h2>
              <div className="hexImg">
                <img src={animlogo} />
              </div>
            </StyledPanel1>
            <StyledHexes>
              <a.img src={verticalhex} width="444" height="1125" />
            </StyledHexes>
            <StyledPanel>
              <div className="content panel-creativity">
                <div className="copy-wrapper">
                  <div className="wrapper">
                    <h2 ref={creativityRef} id="creativity">
                      Creativity is the <span className="filled-text">spark</span> that ignites{' '}
                      <AnimDesire vh={props.vh} startAt={animStart} />
                    </h2>
                  </div>
                </div>
              </div>
            </StyledPanel>

            <div
              style={{
                // height: this.state.vh * 1.25 + 'px',
                position: 'relative',
                zIndex: 10,
              }}
            >
              <HomeMobileSlider
                image={getImage(data.parallaxImage1)}
                alt="la clare"
                description="LaClare Creamery"
                stackIndex={10}
                vh={props.vh}
                enterVh={1.55}
                startAt={animStart + 100}
              >
                <p>01 / 06</p>
                <h3>LaClare Creamery</h3>
                <p>
                  Life’s too short to eat anything less than extraordinary. See how we upped LaClare’s goat cheese game
                  and established their Unrivaled status.
                  <a href="/featured-project/laclare-creamery-food-brand-marketing-strategy/">
                    View Project{' '}
                    <svg width="21" height="16" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.707 8.707a1 1 0 000-1.414L14.343.929a1 1 0 10-1.414 1.414L18.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h20V7H0v2z"
                        fill="#fff"
                      />
                    </svg>
                  </a>
                </p>
              </HomeMobileSlider>
              <HomeMobileSlider
                image={getImage(data.parallaxImage2)}
                alt="Charter Steel"
                stackIndex={11}
                vh={props.vh}
                enterVh={2.2}
                startAt={animStart + 710}
              >
                <p>02 / 06</p>
                <h3>Charter Steel</h3>
                <p>
                  Forged in fire, fueled by their people. Watch how we reignited Charter’s brand to reinforce their rep
                  as an ally in all things steel.
                  <a href="/featured-project/charter-steel-b2b-marketing-brand-video-strategy/">
                    View Project{' '}
                    <svg width="21" height="16" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.707 8.707a1 1 0 000-1.414L14.343.929a1 1 0 10-1.414 1.414L18.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h20V7H0v2z"
                        fill="#fff"
                      />
                    </svg>
                  </a>
                </p>
              </HomeMobileSlider>
              <HomeMobileSlider
                image={getImage(data.parallaxImage3)}
                alt="Berres Brothers"
                stackIndex={12}
                vh={props.vh}
                enterVh={2.85}
                startAt={animStart + 1380}
              >
                <p>03 / 06</p>
                <h3>Berres Brothers</h3>
                <p>
                  Explore the wonders of the Berres Brothers Flavor Factory, a CGI world we crafted to brew up interest
                  in their fantastically flavored coffees.
                  <a href="/featured-project/berres-brothers-integrated-coffee-food-brand-marketing-strategy/">
                    View Project{' '}
                    <svg width="21" height="16" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.707 8.707a1 1 0 000-1.414L14.343.929a1 1 0 10-1.414 1.414L18.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h20V7H0v2z"
                        fill="#fff"
                      />
                    </svg>
                  </a>
                </p>
              </HomeMobileSlider>
              <HomeMobileSlider
                image={getImage(data.parallaxImage4)}
                alt="Legit Pizza"
                stackIndex={13}
                vh={props.vh}
                enterVh={3.5}
                startAt={animStart + 2070}
              >
                <p>04 / 06</p>
                <h3>Legit Pizza</h3>
                <p>
                  We topped ourselves with the Legit™ launch, delivering an authentic brand that cut through in a
                  saturated market. Your house or the best pizza joint around? It’s a toss-up.
                  <a href="/featured-project/legit-pizza-food-brand-marketing-strategy/">
                    View Project{' '}
                    <svg width="21" height="16" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.707 8.707a1 1 0 000-1.414L14.343.929a1 1 0 10-1.414 1.414L18.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h20V7H0v2z"
                        fill="#fff"
                      />
                    </svg>
                  </a>
                </p>
              </HomeMobileSlider>
              <HomeMobileSlider
                image={getImage(data.parallaxImage5)}
                alt="Shopko Optical"
                stackIndex={14}
                vh={props.vh}
                enterVh={4.15}
                startAt={animStart + 2760}
              >
                <p>05 / 06</p>
                <h3>Shopko Optical</h3>
                <p>
                  Brand awareness and community engagement were the name of the game, so we drafted a strategy that
                  positioned Shopko Optical as a leading eye care provider.
                  <a href="/featured-project/shopko-optical-community-outreach/">
                    View Project{' '}
                    <svg width="21" height="16" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.707 8.707a1 1 0 000-1.414L14.343.929a1 1 0 10-1.414 1.414L18.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h20V7H0v2z"
                        fill="#fff"
                      />
                    </svg>
                  </a>
                </p>
              </HomeMobileSlider>
              {/* <div
                style={{
                  position: 'sticky',
                  zIndex: 15,
                  top: '100px',
                  height: '60vh',
                }}
              >
                &nbsp;
              </div> */}
              {/* </LenisParallax> */}
            </div>

            {/* <StyledPanel>
        <StyledLabel className="case-study">Case Study</StyledLabel>
        <a.img
          className="featImg"
          src={featImg}
          style={{
            transform: bigPictureActive ? 'scale(2.5)' : '',
          }}
        />
        <div className="content">
          <h2>Great Work. Bottom Line.</h2>
          <h3>Outstanding Campaign Results</h3>
          <Link to="/case-studies/beefitarian-elevating-retail-category/" className="cta">
            See the Case Study
          </Link>
        </div>
      </StyledPanel> */}
            <StyledPanel3>
              <div
                id="master-hex"
                style={{
                  transform: `translateX(-5px) rotate(90deg) scale(2)`,
                  width: `59px`,
                  margin: '0 auto',
                }}
              >
                <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 15" style={{ width: '100%' }}>
                  <a.path
                    stroke="#fff"
                    fill="transparent"
                    strokeWidth={1.6}
                    d="M1,7.5 l3.6,-6.3 h7.5 l3.6,6.3 l-3.6,6.3 h-7.5 z"
                  />
                </svg>
              </div>

              <div className="content">
                <h2 style={{ marginBottom: '14px' }}>The Element Approach</h2>
                <h3 style={{ color: '#fff' }}>
                  We have people and processes to optimize results and iterate faster than your competitors.
                </h3>
                <Link to="/integrated-marketing-services/" className="cta">
                  See the Difference
                </Link>
              </div>
            </StyledPanel3>
          </>
        );
      }}
    </WithStaticData>
  );
};

export default HomeMobile;
